





































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';
import BasePage from "@/components/pages/BasePage.vue";
import { IChangePassword } from "../interfaces/changePassword";
import ToastService from '@/services/toastService';

const Auth = namespace("Auth");

@Component({
    components: {
        BasePage
    },
})
export default class UsernameAndPassowrd extends Vue {

    @Auth.Action
    private changePassword!: (data: IChangePassword) => Promise<any>;

    @Auth.Getter
    public getUserEmail!: string;

    @Auth.Getter
    public getUserId!: string;

    @Auth.Getter
    private isInImpersonationMode!: boolean;

    private loading = false;
    private confirmNewPasword = "";
    private details = { UserId:"", Email: "", OldPassword: "", NewPassword: "" };
    private showPassword = false;
    private showConfirmPassword = false;
    private showCurrentPassword = false;
    private passwordChanged = false;

    private isExactActive = false;

    public toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    public toggleShowCurrentPassword(): void {
        this.showCurrentPassword = !this.showCurrentPassword;
    }

    public toggleShowConfirmPassword(): void {
        this.showConfirmPassword = !this.showConfirmPassword;
    }

    public handleChangePassword(): void {
        this.loading = true;
        this.details.Email = this.getUserEmail;
        this.details.UserId = this.getUserId;

        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loading = false;
                return;
            }

            if (this.details.OldPassword && this.details.NewPassword) {
                try {
                    this.changePassword(this.details).then((data) => {
                        const result = JSON.parse(data.content).ChangePassword as boolean;
                        if (result) {
                            this.passwordChanged = true;                                
                        }
                        this.loading = false;
                        this.confirmNewPasword = "";
                        this.details = { UserId:"", Email: "", OldPassword: "", NewPassword: "" };
                        this.showPassword = false;
                        this.showConfirmPassword = false;
                        this.showCurrentPassword = false;
                        this.$validator.reset();
                    }, (error) => {
                        this.loading = false;
                        console.error(error);
                    })
                } catch (error) {
                    console.error(error);
                }
            }
            this.loading = false;
        });
    }

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }
}
